<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">员工姓名:</label>
				<el-input v-model="realName" style="width: 200px;" placeholder="请输入员工姓名"></el-input>
			</div>
			<div class="filter-item">
				<label class="label">手机号:</label>
				<el-input v-model="userName" style="width: 200px;" placeholder="请输入登录账号"></el-input>
			</div>
			<div class="filter-item">
				<label class="label">角色:</label>
				<el-select v-model="roleId" placeholder="请选择" filterable clearable style="margin-right: 20px;">
					<el-option :key="-1" label="全部" :value="null"></el-option>
					<el-option
						v-for="(item,index) in dataRoles"
						:key="index"
						:label="item.roleName"
						:value="item.id"
					></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addStaff'">
					<el-button type="primary" style="margin-left:30px;width:90px;" @click="AddEmployee">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border style="width: 100%">
				<el-table-column prop="userName" label="手机号"></el-table-column>
				<el-table-column prop="realName" label="员工姓名"></el-table-column>
				<!-- <el-table-column prop="Phone" label="手机号"></el-table-column> -->
				<el-table-column prop="roles" label="角色"></el-table-column>
				<el-table-column prop="createDate" label="创建时间" width="100px"></el-table-column>
				<el-table-column prop="modifyDate" label="最后登录" width="100px"></el-table-column>
				<el-table-column label="是否启用" width="80px">
					<template slot-scope="scope">
						<el-switch
							v-model="scope.row.status"
							active-color="#13ce66"
							inactive-color="#ff4949"
							@change="changeStatus(scope.row)"
						></el-switch>
					</template>
				</el-table-column>

				<el-table-column label="操作" width="300" align="center">
					<template slot-scope="scope">
						<button-permissions :datas="'editStaff'" style="margin-right: 10px;">
							<el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
						</button-permissions>
						<!-- <button-permissions :datas="'deleteStaff'"> -->
						<el-button type="text" @click="handleLeave(scope.row)" style="color: #F56C6C !important;">删除</el-button>
						<!-- </button-permissions> -->
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination
					v-if="total"
					style="margin-top:20px;"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page=" pageNo"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
				></el-pagination>
			</div>
		</div>

		<!-- 添加员工弹窗 -->
		<el-dialog
			:title="`${editDiaTitle}员工`"
			:visible.sync="dialogFormVisible"
			v-if="dialogFormVisible"
			width="550px"
		>
			<el-form :model="form" :rules="formRules" ref="form">
				<el-form-item label="员工手机 :" label-width="150px" prop="userName">
					<el-input placeholder="请输入员工手机号" maxlength="11" v-model="form.userName" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="员工姓名 :" label-width="150px" prop="realName">
					<el-input placeholder="最多输入20个字" maxlength="20" v-model="form.realName" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="员工角色 :" label-width="150px" prop="roleId">
					<el-select
						v-model="form.roleId"
						placeholder="请选择"
						filterable
						clearable
						style="margin-right: 20px;width:300px;"
					>
						<el-option
							v-for="(item,index) in dataRoles"
							:key="index"
							:label="item.roleName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="登录密码 :" label-width="150px" prop="password" v-if="!form.id">
					<el-input
						placeholder="最多输入20个字"
						maxlength="20"
						v-model="form.password"
						style="width: 300px;"
						type="password"
					></el-input>
				</el-form-item>
				<el-form-item label="确认登录密码 :" label-width="150px" prop="agarnPassword" v-if="!form.id">
					<el-input
						placeholder="最多输入20个字"
						maxlength="20"
						v-model="form.agarnPassword"
						style="width: 300px;"
						type="password"
					></el-input>
				</el-form-item>
				<el-form-item label="备注 :" label-width="150px" prop="userDesc">
					<el-input
						placeholder="最多输入300个字"
						maxlength="300"
						v-model="form.userDesc"
						style="width: 300px;"
						type="textarea"
					></el-input>
				</el-form-item>
			</el-form>
			<div style="padding-top: 20px;">
				<el-button @click="cancelSave" style="width: 100px;margin-left: 100px;">关 闭</el-button>
				<el-button
					type="primary"
					@click="submitSave"
					style="width: 100px;margin-left: 30px;"
					:loading="loading"
				>保 存</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import {
	mapGetters
} from 'vuex'
import {
	employeelist,
	addUser,
	editUser,
	// employeedeparture,
	updateUserStatus,
	employeerolelist,
	employeedelete
} from '@/api/api'
// import {
// 	employeedeparturevalitedata
// } from '@/api/wyUsedInterface'
import buttonPermissions from '@/components/buttonPermissions';

export default {
	components: {
		buttonPermissions
	},
	name: 'employeeManage',
	data () {
		var checkAgarnPassword = (rule, value, callback) => {
			if (value != this.form.password) {
				return callback(new Error('两次密码输入的不相同，请确认！'));
			} else {
				return callback();
			}
		};
		return {
			editDiaTitle: '添加',
			loading: false,
			realName: null,
			userName: null,


			tableData: [],
			pageNo: 1,
			pageSize: 20,
			total: 0,
			dialogFormVisible: false,
			form: {
				id: '',
				realName: '',   //用户姓名
				userName: '',   //用户登录名
				roleId: '',      //角色id
				password: '',   //用户密码
				agarnPassword: '',  //确认密码
				userDesc: '',       //用户简介
			},
			formRules: {
				realName: [{
					required: true,
					message: '请输入员工姓名',
					trigger: 'blur'
				}],
				userName: [{
					required: true,
					message: '请输入员工手机号',
					trigger: 'blur'
				}],
				roleId: [{
					required: true,
					message: '请选择员工角色',
					trigger: 'blur'
				}],
				password: [{
					required: true,
					message: '请输入员工密码',
					trigger: 'blur'
				}],
				agarnPassword: [{
					validator: checkAgarnPassword,
					trigger: 'blur'
				},
				{
					required: true,
					message: '请再次输入员工密码',
					trigger: 'blur'
				}],

			},
			dataRoles: [],  //角色数据
			roleId: '',     //搜索角色id
		}
	},
	mounted () {
		// this.routerList = []
		// this.permission_routers.map(record => {
		// 	if (record.meta && record.meta.title) {
		// 		this.routerList.push(record)
		// 	}
		// })
		// let associtelist = []
		// this.routerList.map(item => {
		// 	if (item.path == '/market') {
		// 		associtelist.push(item)
		// 	}
		// })
		// associtelist[0].children.map(item => {
		// 	if (item.children && item.children.length && item.path == '/market/association') {

		// 		item.children.some(items => {

		// 			if (items.path == '/market/association/associateConfig') {

		// 				this.IsassociateConfig = true
		// 			}
		// 		})
		// 	}
		// })
	},
	beforeMount () {
		this.getRoleList();
		this.getList();
	},
	computed: {
		...mapGetters([
			'permission_routers',
		]),
	},
	methods: {
		async getList () {
			this.loading = true;
			const res = await employeelist({
				realName: this.realName,
				userName: this.userName,
				roleId: this.roleId,
				pageSize: this.pageSize,
				pageNo: this.pageNo,
			})
			this.loading = false;
			if (res.success) {
				for (let i = 0; i < res.data.records.length; i++) {
					if (res.data.records[i].status == 0) {
						res.data.records[i].status = false;
					} else {
						res.data.records[i].status = true;
					}

				}
				this.tableData = res.data.records;
				console.log(this.tableData);
				this.total = res.data.total;
			}
		},
		async getRoleList () {
			const res = await employeerolelist({ pageSize: 10000 });
			if (res.success) {
				this.dataRoles = res.data.records;
			}
		},
		// 筛选
		handleFilter () {
			this.pageNo = 1;
			this.getList();
		},

		// 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.pageNo = val;
			this.getList();
		},
		// 添加员工
		AddEmployee () {
			this.editDiaTitle = '添加'
			this.form = {
				id: '',
				userName: '',
				realName: '',
				roleId: '',      //角色id
				password: '',   //用户密码
				agarnPassword: '',  //确认密码
				userDesc: '',       //用户简介
			}
			this.dialogFormVisible = true;
		},
		// 编辑员工信息
		handleEdit (row) {
			this.editDiaTitle = '编辑'
			this.form = {
				id: row.userId,
				userName: row.userName,
				realName: row.realName,
				roleId: row.roleIds[0],      //角色id
				password: '',   //用户密码
				agarnPassword: '',  //确认密码
				userDesc: row.userDesc,       //用户简介
			}
			this.dialogFormVisible = true;
		},

		// 删除
		async handleLeave (row) {
			this.$confirm('删除后将无法恢复, 是否继续?', '删除员工', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let result = await employeedelete({
					id: row.userId
				});
				if (result.success) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.handleFilter()
				} else {
					this.$message({
						type: 'error',
						message: result.alertMsg
					});
				}
				this.getMenlist();
			}).catch(() => {
				console.log('取消删除');
			});
		},

		// 保存员工信息
		submitSave () {
			this.$refs['form'].validate(async (valid) => {
				if (valid) {
					const loading = this.$loading({
						lock: true,
					});
					try {
						this.loading = true
						let data = {
							id: this.form.id,
							userName: this.form.userName,
							realName: this.form.realName,
							roleId: this.form.roleId,      //角色id
							password: this.form.password,   //用户密码
							userDesc: this.form.userDesc,       //用户简介
						}
						let result;
						if (this.form.id) {
							result = await editUser(data);
						} else {
							result = await addUser(data);
						}

						if (result.success) {
							if (this.editDiaTitle == '添加') {
								this.$message({
									showClose: true,
									type: 'success',
									message: '添加成功！'
								});
							} else {
								this.$message({
									showClose: true,
									type: 'success',
									message: '保存成功！'
								});
							}
							this.dialogFormVisible = false;
							this.$refs['form'].resetFields();
						} else {
							this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg
							});
						}
					} catch (err) {
						console.log(err);
					} finally {
						this.loading = false
						this.realName = ''
						this.handleFilter()
						loading.close();
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},

		cancelSave () {
			this.dialogFormVisible = false;
			this.$refs['form'].resetFields();
		},

		//修改是否启用
		async changeStatus (row) {
			let status = row.status ? '1' : '0';
			const res = await updateUserStatus({
				id: row.userId,
				status: status,
			})
			if (!res.success) {
				row.status = !row.status;
				this.$message({
					showClose: true,
					type: 'error',
					message: res.alertMsg
				});
			}
		}
	},
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
</style>
<style>
.valitedata-dialog-alert {
	width: 550px !important;
}
</style>
